::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(159, 159, 159);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    margin: 7px;
    border-radius: 20px;
    background-color: transparent;
  }


  
  ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .ql-editor {
    min-height: 18em;
    direction: rtl;
    text-align: right;
  }